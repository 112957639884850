import { graphql } from "gatsby"
import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import DepartmentBanner from "../../components/DepartmentBanner/DepartmentBanner"
import { Layout } from "../../components/Layout/Layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import Global_Constant from "../../constants/Global_Contants"
/*
adulthortho_navy_bg : Adult orthopedic
pediatricortho_navy_bg.mp4 : pediatric orthopedic
rheum_sky_bg : Rheumatology
 */

export default ({ data, id }) => {
  const stub = {
    backgroundImage: { localFile: {} },
  }
  const {
    category: {
      slug,
      details_sections: { sections },      
    },
  } = data
  const banner = !!sections ? sections[0] : stub

  const {
    description,
    landingTitle1,
    landingTitle2,
    landingYear,
    mainTitle,
    socialShareImage
  } = banner

  const transitionCoverRef = useRef()
  const layoutContentsRef = useRef()
  const { publicURL } = socialShareImage?.localFile || {}

  const verticalAnimation = ({ length }, direction) => {
    const directionTo = direction === "up" ? "-100%" : "100%"
    const directionFrom = direction === "up" ? "100%" : "-100%"

    // convert ms to s for gsap
    const seconds = length

    return gsap
      .timeline()
      .set(transitionCoverRef.current, { y: directionFrom })
      .to(transitionCoverRef.current, {
        y: "0%",
        ease: "power1.easeInOut",
        duration: seconds / 2,
      })
      .set(layoutContentsRef.current, { opacity: 0 })
      .to(transitionCoverRef.current, {
        y: directionTo,
        ease: "power1.easeIn",
        duration: seconds / 2,
      })
  }

  useEffect(() => {
    if (
      typeof window !== undefined &&
      document.body.classList.contains("article-loaded")
    ) {
      document.body.classList.remove("article-loaded")
    }
  }, [])

  return (
    <>
      <GatsbySeo
        title={`${mainTitle}`}
        description={description && description.length ? `${description}` : `${Global_Constant.SEOdescription}`}
        canonical={`${process.env.WP_SITE_URL}${slug}`}
        openGraph={{
          url: `${process.env.WP_SITE_URL}${slug}`,
          title: ` ${mainTitle} | ${landingTitle1} - ${landingTitle2}`,
          description: description && description.length ? `${description}` : `${Global_Constant.SEOdescription}`,
          site_name: `HSS | ${landingTitle1} | ${mainTitle}`,
          type: "department",
          images: [{
            url: `${publicURL}`
          }]
        }}
      />
      <Layout verticalAnimation={verticalAnimation}>
        <main
          className={"landing landing-dept dept-po categoryPage " + slug}
          ref={layoutContentsRef}
        >

          <DepartmentBanner {...banner} />
        </main>
        <div className="cloneSlideWrap">
          <div className="cloneCard cloneCard1"></div>
          <div className="cloneCard cloneCard2"></div>
          <div className="cloneCard cloneCard3"></div>
          <div className="cloneCard cloneCard4"></div>
        </div>
        <TransitionPortal>
          <div
            ref={transitionCoverRef}
            className="curtain-loader"
            style={{
              position: "fixed",
              background: "#1aafe5",
              top: 0,
              right: 0,
              width: "100%",
              height: "100vh",
              transform: "translateY(100%)",
            }}
          />
        </TransitionPortal>
      </Layout>
    </>
  )
}

export const query = graphql`
  query category($id: String!) {
    category: wpCategory(id: { eq: $id }) {
      slug
      name
      details_sections {
        sections {
          ...departmentBanner
        }
      }
    }
  }

  fragment departmentBanner on WpCategory_DetailsSections_Sections_BannerSection {
    description
    fieldGroupName
    landingTitle1
    landingTitle2
    landingYear
    mainTitle
    backgroundVideoOption
    backgroundImage {
      localFile {
        publicURL
      }
    }
    socialShareImage {
      localFile {
        publicURL
      }
    }
    cardsList {
      cardType
      bigCardTitle
      fieldGroupName
      videoUrl
      mobileVideoUrl
      selectPost {
        ...PostDetailLight
      }
      videoSliderList {
        fieldGroupName
        sliderDescription
        sliderNumber
        sliderTitle
        sliderIcon {
          localFile {
            publicURL
          }
        }
      }
      bigCardRightList {
        bigCardLeftListTitle
        bigCardRightListLink
        fieldGroupName
      }
      bigCardLeftList {
        bigCardLeftListTitle
        bigCardLeftListLink
        fieldGroupName
      }
      bigCardImage {
        altText
        localFile {
          publicURL
        }
      }
    }
  }

  fragment PostDetailLight on WpPost {
    id
    uri
    title
    status
    slug
    post {
      listDesciption
      fieldGroupName
      heroBackgroundImage {
        id
        sourceUrl
      }
      backgroundImage {
        title
        localFile {
          publicURL
        }
      }
      articleOrder
    }
  }
`
